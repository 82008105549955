import React from 'react';

function App() {
  // Just return an empty div for a blank page
  return (
    <div>
      {/* This page is intentionally left blank */}
    </div>
  );
}

export default App;
